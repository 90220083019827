import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import React, { Suspense } from 'react';

import { connect } from "react-redux";

import { auth } from "../store/actions";
import LandingResponsive from '../routes/LandingResponsive';
import LoadingPage from './LoadingPage';

const NotFound = React.lazy(() => import('../routes/NotFound'));
//const LandingResponsive = React.lazy(() => import('../routes/Landing'));
const Dashboard = React.lazy(() => import('../routes/Dashboard'));
const Login = React.lazy(() => import('../routes/Login'));
const Signup = React.lazy(() => import('../routes/Signup'));
const Artwork = React.lazy(() => import('../routes/Artwork'));
const Tool = React.lazy(() => import('../routes/Tool'));
const Library = React.lazy(() => import('../routes/Library'));
const LibraryList = React.lazy(() => import('../routes/LibraryList'));
const LibraryEdit = React.lazy(() => import('../routes/LibraryEdit'));
const ArtworkList = React.lazy(() => import('../routes/ArtworkList'));
const ArtworkSingle = React.lazy(() => import('../routes/ArtworkSingle'));
const ToolList = React.lazy(() => import('../routes/ToolList'));
const ToolEdit = React.lazy(() => import('../routes/ToolEdit'));
const PublicObject = React.lazy(() => import('../routes/PublicObject'));
// const UserProfile = React.lazy(() => import('../routes/UserProfile'));

class RootContainer extends React.Component {

  componentDidMount() {
    this.props.loadUser();
  }

  PrivateRoute = ({ component: ChildComponent, roles_allowed, ...rest }) => {
    return <Route {...rest} render={props => {
      if (this.props.auth.isLoading) {
        return <em>Loading...</em>;
      } else if (!this.props.auth.isAuthenticated) {
        return <Redirect to="/login" />;
      } else if (roles_allowed
                 && !roles_allowed.some(role => this.props.auth.user.rolenames.includes(role))) {
        return <Redirect to="/login" />;
      } else {
        //console.log(this.props.auth.user);
        return <ChildComponent {...props} />
      }
    }} />
  }

  render() {
    let { PrivateRoute } = this;

    if (this.props.auth.isLoading)
      return <p>Loading...</p>;

    return (
      <BrowserRouter>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          {this.props.auth.isAuthenticated
            ? <PrivateRoute exact path="/" component={Dashboard} />
            : <Route exact path="/" component={LandingResponsive} />}

          <PrivateRoute exact path='/libraries/create' roles_allowed={['create_library']} component={Library} />
          <PrivateRoute exact path='/libraries/edit/:uuid' roles_allowed={['create_library']} component={LibraryEdit} />
          <PrivateRoute exact path='/libraries' roles_allowed={['create_library', 'view_library']} component={LibraryList} />

          <PrivateRoute exact path='/tools/create' roles_allowed={['create_tool']} component={Tool} />
          <PrivateRoute exact path='/tools/edit/:uuid' roles_allowed={['create_tool']} component={ToolEdit} />
          <PrivateRoute exact path='/tools' roles_allowed={['create_tool', 'view_tool']} component={ToolList} />

          <PrivateRoute exact path='/paintings' component={ArtworkList} />
          <PrivateRoute exact path='/paintings/:uuid' component={ArtworkSingle} />

          <Route exact path='/create/artwork' component={Artwork} />
          <Route exact path='/view' component={PublicObject} />

          <Route exact path="/signup" component={Signup} />
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/:username" component={UserProfile} /> */}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadUser: () => {
      return dispatch(auth.loadUser());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
