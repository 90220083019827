const debugConsole = (func) => {
  return (...args) => {
    if (process.env.NODE_ENV === 'production')
      return;
    func.apply(this, args);
  }
}

export default {
  log: debugConsole(console.log),
  info: debugConsole(console.info),
  warn: debugConsole(console.warn),
  error: debugConsole(console.error),
};
