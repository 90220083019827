import * as auth from "./auth";

export const AUTH_MISSING_TOKEN = 'AUTH_MISSING_TOKEN';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_USER_LOADING = 'AUTH_USER_LOADING';
export const AUTH_USER_LOADED = 'AUTH_USER_LOADED';
export const AUTH_REGISTRATION_SUCCESSFUL = 'REGISTRATION_SUCCESSFUL';
export const AUTH_REGISTRATION_FAILED = 'REGISTRATION_FAILED';
export const AUTH_LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const AUTH_LOGIN_FAILED = 'LOGIN_FAILED';
export const AUTH_LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';

export { auth };
