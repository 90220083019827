import React from 'react';
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';
import '../css/landing.css';
import console from '../utils/console';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const makeVideoUrl = (name) => `examples/${name}-html.mp4`

export default class LandingResponsive extends React.Component {
  videoRef = React.createRef();
  video2Ref = React.createRef();

  state = {
    email: '',
    timeout: null,
    currentUrl: makeVideoUrl('boatlake'),
    currentUrlIndex: 0,
    show: false,
    width: 0,
    height: 0,
    urlList: [
      makeVideoUrl('boatlake'),
      makeVideoUrl('cup'),
      makeVideoUrl('flower'),
      makeVideoUrl('girl2'),
      makeVideoUrl('girl3'),
      makeVideoUrl('honeyjar'),
      makeVideoUrl('sunset'),
    ]
  }

  componentDidMount() {
    window.analytics.page('Landing');
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
    console.log(window.innerWidth, window.innerHeight)
  }

  handleVideoEnded = () => {
    // Clear old timeout, if any
    if (this.state.timeout)
      clearTimeout(this.state.timeout);
    // Restart video after a delay
    this.setState({
      timeout: setTimeout(() => {
        this.setState((oldState) => {
          return { currentUrlIndex: (oldState.currentUrlIndex + 1) % oldState.urlList.length }
        }, () => {
          //this.videoRef.current.seekTo(0)
        })
      }, 2000)
    });
  }

  render() {
    return (
      <div className='bg-main'>
        <div className='sm:flex min-h-screen md:items-center 2xl:w-4/5 3xl:w-1/2 md:mx-auto'>
          <div style={{ minHeight: this.state.width < 640 ? this.state.width : 0 }} className='sm:w-full md:w-1/2 bg-green mb-4 flex-shrink-0 md:m-4'>
            <ReactPlayer
              className="mx-auto h-full md:rounded md:overflow-hidden"
              url={this.state.urlList[this.state.currentUrlIndex]}
              playing
              muted
              forceVideo
              playsinline
              ref={this.videoRef}
              onEnded={this.handleVideoEnded}
              width='100%'
              height='100%'
            />
          </div>

          <div className="sm:w-full md:w-1/2 bg-blue p-4">
            <h1 className="text-title text-gray-900 font-bold font-site leading-tight text-left pb-2">
              Artbuffer
              </h1>
            <h2 className="text-xl text-gray-800 font-site leading-tight text-left pb-6">
              Generative Painting Made Simple
              </h2>

            <p className="leading-normal text-base font-thin font-site text-left pb-6 text-gray-700">
              Artbuffer lets you easily convert your photos into beautiful digital paintings using generative brushes.
            </p>

            {/* <form action="https://artbuffer.us20.list-manage.com/subscribe/post?u=14aacfac0bdb665cec195841c&amp;id=b2d61c4a7b" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="" target="_blank" noValidate>
              <input
                id="mce-EMAIL"
                type="email"
                name="EMAIL"
                value={this.email}
                onChange={(e) => this.setState({email: e.target.value})}
                className="px-3 py-3 pl-4 rounded shadow font-site flex-1 text-sm w-full 3xl:w-auto 3xl:mr-2 3xl:flex-grow mb-2"
                placeholder='Your email address'
              />
              <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                <input type="text" name="b_14aacfac0bdb665cec195841c_b2d61c4a7b" tabIndex="-1" defaultValue="" />
              </div>
              <input type="submit" value="Email Me on Launch" name="subscribe" id="mc-embedded-subscribe" className="bg-green-400 py-3 px-5 w-full shadow-md rounded text-sm uppercase text-green-900 font-site 3xl:w-auto 3xl:inline-block" />
            </form> */}

            <Link to='/create/artwork'>
              <button className="bg-green-400 hover:bg-green-500 py-3 mb-3 px-5 w-full shadow-md rounded text-sm uppercase text-green-900 font-site 3xl:w-auto 3xl:inline-block">Go to Editor <FontAwesomeIcon size='lg' icon={['fas', 'arrow-alt-right']} /></button>
            </Link>
            <Link to='/login'>
              <button className="bg-white hover:bg-gray-200 py-3 px-5 w-full shadow-lg rounded text-sm uppercase text-green-900 font-site 3xl:w-auto 3xl:inline-block">Sign In</button>
            </Link>

            <div className="w-full pt-4 text-center md:text-left fade-in font-site text-xs text-gray-500">
              &copy; 2019 <a className="text-gray-500 no-underline hover:underline" href="https://www.twitter.com/sighack" target='_blank' rel="noopener noreferrer">Manohar Vanga</a>
            </div>

          </div>
        </div>
        <div className='flex justify-center'>
          <a className='footer-link' target='_blank' rel='noopener noreferrer' href='https://www.iubenda.com/privacy-policy/69476695/full-legal'>Privacy Policy</a>
          <a className='footer-link' target='_blank' rel='noopener noreferrer' href='https://www.iubenda.com/privacy-policy/69476695/cookie-policy'>Cookie Policy</a>
          <a className='footer-link' target='_blank' rel='noopener noreferrer' href='https://www.iubenda.com/terms-and-conditions/69476695'>Terms and Conditions</a>
        </div>
      </div>
    )
  }
}
