import React from 'react'

export default function LoadingSpinner(props) {
  const h = props.height || 32;
  const w = props.width || 32;
  return (
    <div className='h-screen w-screen flex items-center justify-center'>
      <p className='text-lg font-site text-gray-700'>
        <img alt='loading spinner' height={h} width={w} src='/spinner.gif' />
      </p>
    </div>
  )
}
