import config from '../../config.js';
import console from '../../utils/console';

export const loadUser = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    if (!token) {
      dispatch({type: 'MISSING_TOKEN', data: {}});
      return;
    }

    dispatch({ type: 'USER_LOADING' });

    if (!token) {
      dispatch({
        type: 'AUTH_ERROR',
        data: { errors: { general: ['You are not logged in.'] } },
      });
    }

    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return fetch(config.apiUrl() + '/api/v1/me', { headers })
      .then(res => {
        res.json().then(data => {
          dispatch({ type: (res.ok ? 'USER_LOADED' : 'AUTH_ERROR'), data });
        })
      })
      .catch(err => {
        // Server errors or connection errors end up here
        dispatch({
          type: 'AUTH_ERROR',
          data: { errors: { general: ['Something went wrong. Please try again later.'] } },
        });
      })
  }
}


export const login = (username, password, callback=null) => {
  return (dispatch, getState) => {
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };
    let body = JSON.stringify({ user_or_email: username, password });

    return fetch(config.apiUrl() + '/api/v1/sessions', { headers, body, method: 'POST' })
      .then(res => {
        res.json().then(data => {
          dispatch({ type: (res.ok ? 'LOGIN_SUCCESSFUL' : 'LOGIN_FAILED'), data });
          if (res.ok && callback) callback(data);
        })
      })
      .catch(err => {
        // Server errors or connection errors end up here
        dispatch({
          type: 'LOGIN_FAILED',
          data: { errors: { general: ['Something went wrong. Please try again later.'] } },
        });
      })
  }
}

export const logout = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'LOGOUT_SUCCESSFUL' });
  }
}

export const signup = (email, username, password, callback=null) => {
  return (dispatch, getState) => {
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };
    let body = JSON.stringify({ email, username, password });
    console.log(body);

    return fetch(config.apiUrl() + '/api/v1/users', { headers, body, method: 'POST' })
      .then(res => {
        res.json().then(data => {
          dispatch({ type: (res.ok ? 'REGISTRATION_SUCCESSFUL' : 'REGISTRATION_FAILED'), data });
          if (res.ok && callback) callback(data);
        })
      })
      .catch(err => {
        // Server errors or connection errors end up here
        dispatch({
          type: 'REGISTRATION_FAILED',
          data: { errors: { general: ['Something went wrong. Please try again later.'] } },
        });
      })
  }
}
