const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  isLoading: true,
  user: null,
  errors: {},
};


export default function auth(state = initialState, action) {
  const { type, data } = action;
  switch (type) {

    case 'USER_LOADING':
      return {
        ...state,
        isLoading: true
      };

    case 'USER_LOADED':
      window.analytics.identify(data.user.id, data.user);
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: data.user
      };

    case 'LOGIN_SUCCESSFUL':
    case 'REGISTRATION_SUCCESSFUL':
      localStorage.setItem('token', data.access_token);
      window.analytics.identify(data.user.id, data.user);
      return {
        ...state,
        errors: null,
        token: data.access_token,
        user: data.user,
        isAuthenticated: true,
        isLoading: false,
      };

    case 'LOGOUT_SUCCESSFUL':
      localStorage.removeItem('token');
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        user: null,
        errors: null,
      };

    case 'REGISTRATION_FAILED':
    case 'LOGIN_FAILED':
    case 'AUTH_ERROR':
      localStorage.removeItem('token');
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errors: data.errors,
        token: null,
        user: null,
      };

    case 'MISSING_TOKEN':
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errors: null,
        token: null,
        user: null,
      };

    default:
      return state;
  }
}
